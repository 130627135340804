import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="enable-on-check"
export default class extends Controller {
  static targets = [ "botao" ]

  change(e) {
    this.botaoTarget.disabled = !e.target.checked
  }
}
